<template>
  <div class="ba_lin_you_qi_map">
    <v-chart :options="options" autoresize @click="clickMap" @dblclick="goToHamlet" />

    <img 
      ref="mapSvg" 
      id="mapSvg" 
      v-show="false"
      style="width:500px;height:500px;" 
      src="../../../../../assets/images/user/user-avatar.svg"
    >
  </div>
</template>

<script>
import * as echarts from "echarts";
import '../../../../../assets/js/map/da-ban-hamlet'
import { getXiangZhenTongJi } from '../../../../../config/api';

let geoCoordMap = {};
let data = [
  { name: '红旗嘎查', selected: true, value: 3289 },
  { name: '吉布吐嘎查', selected: false, value: 2578 },
  { name: '西哈嘎查', selected: false, value: 1956 },
  { name: '十家子嘎查', selected: false, value: 1664 },
  { name: '查干诺尔嘎查', selected: false, value: 1239 },
  { name: '浩饶沁嘎查', selected: false, value: 1217 },
  { name: '大冷嘎查', selected: false, value: 1189 },
  { name: '古力古台嘎查', selected: false, value: 1078 },
  { name: '苏艾力嘎查', selected: false, value: 819 },
  { name: '太布呆嘎查', selected: false, value: 784 },
  { name: '昭胡都格嘎查', selected: false, value: 526 },
  { name: '新达冷村', selected: false, value: 517 },
  { name: '苏艾里嘎查', selected: false, value: 489 },
  { name: '西拉汰沦厚查', selected: false, value: 475 },
  { name: '巴彦汉嘎查', selected: false, value: 379 },
  { name: '温根吐嘎查', selected: false, value: 334 },
  { name: '翁根图毛都嘎查', selected: false, value: 321 },
  { name: '红星村', selected: false, value: 267 },
  { name: '莫日古其格', selected: false, value: 229 },
  { name: '独希嘎查', selected: false, value: 219 },
  { name: '新立村', selected: false, value: 207 },
  { name: '友爱村', selected: false, value: 198 },
  { name: '益斯毛都嘎查', selected: false, value: 169 },
  { name: '准宝日嘎查', selected: false, value: 157 },
  { name: '巴罕村', selected: false, value: 142 },
  { name: '套白村', selected: false, value: 115 }
];

let convertData = function (data) {
  let res = [];
  for (let i = 0; i < data.length; i++) {
    let geoCoord = geoCoordMap[data[i].name];
    if (geoCoord) {
      res.push({
        name: data[i].name,
        value: geoCoord.concat(data[i].value),
      });
    }
  }
  return res;
};

let max = 6000,
  min = 10;
let maxSize4Pin = 100,
  minSize4Pin = 20;

export default {
  name: "DabanAllHamlet",
  props: {
    value: Object,
    ispreview: Boolean,
  },
  data() {
    return {
      xiangZhenTongJi: [],  // 乡镇统计
      gaChaCunTongJi: [],   // 嘎查村统计
      oneGaChaCunTongJi: [],   // 一个嘎查村统计
      showGaChaCunTongJi: false,
      showOneGaChaCunTongJi: false,

      isShowDialog: false,
      dialogtTitle: '',
      userClick: false,
      dialogTitle: '',
      dialogHamletTitle: '',

      zhuZhaungTuData: {},

      map: null,
      mapName: '索博日嘎镇',
      mapLabel: require('../../../../../assets/images/ba-lin-you-qi/xiao-niu2.png'),

      options: {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (params.value.length > 1) {
              return params.data.name + "" + params.data.value[2];
            } else {
              return params.name;
            }
          },
        },
        series: [
          {
            type: "map",
            map: "dabanhamlet",
            aspectScale: 0.75,
            show: false,
            roam: true,
            zoom: true,
            scaleLimit: {
              //滚轮缩放的极限控制
              min: 0.5, //缩放最小大小
              max: 6, //缩放最大大小
            },
            label: {
              normal: {
                //调整数值
                position: "right",
                show: true,
                color: "#53D9FF",
                fontSize: 14,
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                //地图块颜色
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#073684", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#061E3D", // 100% 处的颜色
                    },
                  ],
                },
                borderColor: "#215495",
                borderWidth: 1,
              },
              //鼠标放置颜色加深
              emphasis: {
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#073684", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#2B91B7", // 100% 处的颜色
                    },
                  ],
                },
              },
            },
            data: data,
          }
        ],
      },
    };
  },
  mounted() {
    this.getXiangZhenTongJi()

    setTimeout(() => {
      console.log(this.options.series[0].itemStyle.normal.areaColor);

      this.options.series[0].itemStyle.normal.areaColor = {
        image: this.$refs.mapSvg,
        repeat: 'repeat',
      }
    }, 1000)    
  },
  methods: {
    // 获取乡镇统计
    async getXiangZhenTongJi() {
      let res = await getXiangZhenTongJi()
      this.xiangZhenTongJi = res.data.results
    },

    goToHamlet() {
      this.$router.push({
        path: "/bigscreen/villagePage3"
      })
    },

    // 点击 map
    clickMap(e) {
      this.mapName = e.name
      console.log(e.name);

      data.forEach(item => {
        item.selected = item.name == e.name ? true : false
      })  

      this.$emit('changeHamletName', e.name)
    },

    // 跳转到详情页面
    goToDetail(val) {
      // 方案1
      this.$router.push({
        path: "/bigscreen/detail/" + val
      })

      // 方案2
      // localStorage.setItem('village', val)
    }
  },
};
</script>
<style lang="scss" scoped>

.ba_lin_you_qi_map {
  width: 100%;
  height: 100%;
}

.echartMap {
  width: 100%;
  height: 100%;
  color: #d4eeff;
}

.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.village {
  font-size: 26px;
  color: #fff;
  position: relative;
  top: 70px;
  left: 70px;
  z-index: 9999999;
}
.village:hover {
  cursor: pointer !important;
  color: #2366fc !important;
}

.tong_ji_dialog {
  width: 900px;
  height: 700px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
  z-index: 99999;

  .tong_ji_title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #2366fc;
    color: #fff;
    font-size: 20px;
    
  }

  .tong_ji_list {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
    // border: 1px solid #f00;
    .tong_ji_item {
      padding: 10px;
      border-bottom: 1px solid #eee;
      .tong_ji_name {
        text-align: left;
        padding: 10px;
        font-size: 18px;
        color: #212121;
        cursor: pointer;
        font-weight: bold;
      }

      span { font-size: 18px; margin-right: 20px; }
    }
  }

  .footer_btn {
    margin-top: 20px;
  }
}

// 询问跳转 dialog
.xun_wen_dialog {
  width: 100%;
  height: 100%;
  background-color: rgba(#212121, .8);
  position: relative;
  top: 0;
  left: 0;

  .xun_wen {
    width: 50%;
    height: 300px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;

    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #2366fc;
      color: #fff;
      font-size: 20px;
    }

    .footer_btn {
      margin-top: 20px;
    }
  }
}

.mo-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.el-dialog__wrapper {
  background-color: rgba(#212121, .6);

  .el-dialog {
    margin-top: 47% !important;
  }
}
.el-dialog {  margin-top: 47% !important; }
</style>
